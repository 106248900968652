import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'normalize.css/normalize.css'

import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css';
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

import splitPane from 'vue-splitpane'
Vue.component('split-pane', splitPane)

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})