<template>
  <div style="width: 100%; height: 100vh;font-size: 16rem;position: relative;">
    <div class="horizontal" style="padding:10rem;align-items: center;">
      <img style="display: block; width:60rem; height:60rem;" src="../assets/logo2.png">
      <div style="font-weight: bold;font-size: 30rem;padding-left:15rem;">AI video</div>
      <!--
      <div
        style="position: absolute; right:10rem;background: #222222; border-radius: 25rem;padding:10rem 15rem;color: yellow;font-size: 20rem;"
        @click="buy">
        购买AI币
      </div>
      -->
    </div>

    <split-pane :min-percent="20" :default-percent="50" split="vertical" style="border: #666666 solid 1rem;">
      <template slot="paneL">
        <div v-if="video_url" style="height: 100%;padding:0 20rem;overflow: auto;">
          <video-player class="video-player vjs-custom-skin"
            ref="videoPlayer1" 
            :playsinline="true"
            @timeupdate="onPlayerTimeupdate($event)"
            :options="play_options1">
          </video-player>
          <div class="horizontal">
            <div style="margin:10rem auto;font-size: 20rem;" @click="playVideoSeg(-1)">上一段</div>
            <div style="margin:10rem auto;font-size: 20rem;" @click="playVideoSeg(1)">下一段</div>
          </div>
          <div v-if="video_seg_idx>=0">当前段长度: {{ video_seg_list[video_seg_idx][1] - video_seg_list[video_seg_idx][0] }}</div>
        </div>
        <div v-else style="display: flex;justify-content: center;align-items: center;height: 80%;">
          <div style="background: #eeeeee;border-radius: 15rem;padding: 25rem;">
            <div style="font-size: 18rem;margin-bottom: 15rem;font-weight: bold;">上传视频</div>
            <input type="file" style="height:40rem;" accept="video/*" @change="checkFile"/>
            <div style="line-height: 25rem;margin:15rem 0;">
              <span style="font-weight: bold;">要求:<br></span>1. 大小不超过300M<br>2. 支持MP4格式
            </div>
            <div style="margin:0 auto;width:50rem;text-align: center; background: #666666;padding: 10rem;border-radius: 10rem;color:white;" @click="loadVideo">
              确认
            </div>
          </div>
        </div>
      </template>
      <template slot="paneR">
        <div class="message-container" ref="messagecontainer">
          <div v-for="(s,index) in messages" :key="index"
            :class="{ 'message-left': s.role != 'B', 'message-right': s.role === 'B' }">
            <img v-if="curr_audio_idx==index && sound && sound.playing()" class="play-button" src="../assets/audio-icon-pause.png" @click="playAudio(index)">
            <img v-else class="play-button" src="../assets/audio-icon-play.png" @click="playAudio(index)">
            <div style="margin-bottom: 10rem;">{{ s.text }}</div>
            <input style="border-style: dotted;width:98%;height: 30rem;padding:5rem;"
              @input="inputChange(index)"
              v-model="s.times" placeholder="点击'/'自动插入当前视频时间">
          </div>
        </div>
      </template>
    </split-pane>
    
    <div style="position:absolute;left:100rem;top:100rem;width: 60%;height: 60%;background: #B6D7FF;opacity: 0.3;filter: blur(300rem);z-index: -1;"></div>

    <div class="mask" v-if="userID.length == 0">
      <div class="horizontal" style="background: #f6f6f6;border-radius: 15rem;padding: 25rem;align-items: center;">
        <input style="font-size: 18rem;border-style: dotted;width:200rem;height: 30rem;padding:5rem;" placeholder="请输入邀请码">
        <div style="margin-left:10rem;text-align: center; background: #666666;padding: 10rem 20rem;border-radius: 10rem;color:white;">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Howl } from 'howler'
import { Toast } from 'vant'
import { PLAY_OPTION } from '../components/util'

export default {
  components: {},
  data() {
    return {
      userID: "10000001",
      userName: "Jack",

      play_options1 :PLAY_OPTION,

      messages:[],
      video_file:null,
      video_url:"",
      video_seg_list:[],
      video_seg_idx:-1,

      video_curr_time:0,
      curr_audio_idx:-1,
      sound:null,

      loading: false
    }
  },

  created() {
    document.title = ''
    this.getRouterData()
  },
  destroyed() {
    this.sound && this.sound.stop()
    //
  },
  methods: {
    getRouterData() {
      //
    },
    buy() {
      Toast("购买功能暂未开放")
    },
    checkFile(event) {
      if(event.target.files.length>0){
        this.video_file = event.target.files[0]
        console.log(this.video_file.name)
      }
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messagecontainer;
        container.scrollTop = container.scrollHeight
      });
    },

    playAudio(index) {
      if (this.curr_audio_idx != index) {
        //
        this.sound && this.sound.stop()
        this.curr_audio_idx = index
        
        let wav = this.messages[index].audio_url
        console.log("initial sound", wav)
        this.sound = new Howl({
          src: [wav],

          onplay: () => {
            console.log("开始播放啦")
          },
          onpause: () => {
            console.log("结束播放啦 onpause")
          },
          onstop: () => {
            console.log("结束播放啦 onstop")
          },
          onend: () => {
            console.log("结束播放啦 onend")
          },
          onloaderror: () => {
            Toast("声音导入失败")
          },
          onplayerror: () => {
            Toast("声音播放失败")
          },
        })
      }
      this.sound[this.sound.playing() ? 'pause' : 'play']()
    },
    onPlayerTimeupdate(player) {
      this.video_curr_time = player.cache_.currentTime
      if(this.video_seg_idx>=0 && this.video_curr_time >= this.video_seg_list[this.video_seg_idx][1]){
        this.$refs.videoPlayer1.player.pause()
      }
    },

    playVideoSeg(type){
      if(type==-1){
        if(this.video_seg_idx>0){
          this.video_seg_idx -= 1
          this.$refs.videoPlayer1.player.currentTime(this.video_seg_list[this.video_seg_idx][0])
          this.$refs.videoPlayer1.player.play()
        }
      } else if(this.video_seg_idx<this.video_seg_list.length-1){
        this.video_seg_idx += 1
        this.$refs.videoPlayer1.player.currentTime(this.video_seg_list[this.video_seg_idx][0])
        this.$refs.videoPlayer1.player.play()
      }
    },

    inputChange(index){
      if(this.messages[index].times.slice(-1)=='/'){
        this.messages[index].times = this.messages[index].times.slice(0, -1) + this.video_curr_time.toFixed(2) + ", "
      }
    },

    async loadVideo(){
      /*
      if(this.video_file==null){
        Toast("请先选择视频")
        return
      }
      this.video_url = ""

      const formData = new FormData();
      formData.append("file", this.video_file)
      await axios
        .post("https://caixiaowen.com/zsp", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data)
          if(response.data.err_no==0) this.video_url = response.data.filepath
        })
        .catch((error) => {
          console.error(error)   
        })
      if(this.video_url==""){
        Toast("视频上传失败")
        return
      }
      */

      let request_data = {
        'type': 'ai_video_example',
        'data': {
          'user_id': this.userID
        }
      }
      axios.post('https://caixiaowen.com/zsp', JSON.stringify(request_data))
        .then((result) => {
          console.log(result.data)
          this.video_url = result.data.video_url
          this.play_options1.sources=[{
            type: "video/mp4",
            src: this.video_url
          }]
          //
          this.messages = result.data.messages
          this.video_seg_list = result.data.video_seg_list
          
          this.scrollToBottom()

        }).catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
.horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: flex-start;
}

.title {
  text-align: center;
  margin-top: 10rem;
  padding: 0 10rem;
  font-size: 16rem;
  width: 120rem;
  color: #222222;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.waitingImg {
  display: block;
  width: 20rem;
  height: 20rem;
  animation: rotateImg 8s linear infinite;
}
@keyframes rotateImg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.message-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
}

.message-left {
  align-self: flex-start;
  background-color: rgba(247,247,247,1.0);
  color: rgba(34, 34, 34, 0.9);

  margin: 10rem;
  padding: 15rem;
  border-radius: 8rem;
  max-width: 80%;
  font-size: 18rem;
  line-height: 22rem;
}

.message-right {
  align-self: flex-end;
  background-color: rgba(247,247,247,1.0);
  color: rgba(0, 0, 200, 0.9);
  font-weight: bold;

  margin: 10rem;
  padding: 15rem;
  border-radius: 8rem;
  max-width: 80%;
  font-size: 18rem;
  line-height: 22rem;
}

.play-button {
  display: block;
  width:30rem;
  height:30rem;
  margin-bottom: 10rem;
}

.selected_border {
  border: red solid 2rem;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .3);
  z-index: 9999;

  width: 100%;
  height: 100%vh;

  display: flex;
  align-items: center;
  justify-content: center;
}</style>