
<template>
  <div style="background: #f6f6f6;border-radius: 15rem;padding: 25rem;font-size: 18rem;position: relative;">
    <div v-if="qr_code>=0" style="flex-direction:column;display:flex;text-align: center; ">
      <div style="font-size:28rem;">微信扫码</div>
      <div style="font-size:16rem;padding-top: 5rem;">支付金额: {{ order_list[check_idx].pay_money }}元</div>
      <div v-if="qr_code==0" style="margin-top: 20rem;">正在生成支付二维码...</div>
      <div style="margin: 20rem auto;" ref="qrCodeUrl"></div>
      <div>提示: 点击"完成"后会刷新购买结果</div>
      <div style="height:1rem;margin:10rem 0;background: #999999;"></div>
      <div style="font-size:20rem;background: #715af6;padding: 10rem 15rem;border-radius: 5rem;color:white;" @click="close(1)">完成</div>
    </div>
    <div v-else>
      <img style="position: absolute ;top:10rem;right:10rem;width:20rem;height:20rem;" src="../assets/close_x.png" @click="close(0)"/>
      <div style="text-align: center; font-size:28rem;padding-bottom: 15rem;">购买代币</div>
      <div style="font-size:16rem;padding-bottom: 15rem;color:#999999;">当前余额: {{ aiMoney }} AI币</div>
      <div class="horizontal" style="max-width:380rem;">
        <div v-for="(item, index) in order_list" :key="index" style="padding: 10rem 0;min-width:170rem;">
          <input type="radio" v-model="item.check" value=0 @click="check(index)">
          <span style="color:red">{{ item.ai_money }}</span>
          <span> AI币 </span>
          <span style="color:red">{{ item.pay_money }}</span>
          <span>元</span>
        </div>
      </div>
      <div style="height:1rem;margin:10rem 0;background: #999999;"></div>
      <div class="horizontal" style="justify-content: center;">
        <div style="font-size:20rem;text-align: center; background: #715af6;padding: 10rem 15rem;border-radius: 5rem;color:white;" @click="creatQrCode">立即购买</div>
        <!--<div style=" margin-left:30rem;font-size:20rem;text-align: center; background: #853e82;padding: 10rem 15rem;border-radius: 5rem;color:white;">VIP专属定制</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import QRCode from 'qrcodejs2-fix'
//import { Toast } from 'vant'

export default {
  components: {},
  props: {
    userID: {
      type: String,
      required: true
    },
    aiMoney: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      order_list:[{
        ai_money:"10",
        pay_money:"10",
        order_type:201,
        check:false
      },{
        ai_money:"30",
        pay_money:"30",
        order_type:202,
        check:false
      },{
        ai_money:"50",
        pay_money:"48",
        order_type:203,
        check:false
      },{
        ai_money:"100",
        pay_money:"92",
        order_type:204,
        check:false
      },{
        ai_money:"500",
        pay_money:"448",
        order_type:205,
        check:false
      },{
        ai_money:"限时 1000",
        pay_money:"800",
        order_type:206,
        check:false
      }],
      check_idx:-1,
      qr_code:-1
    }
  },

  methods: {
    check(index){
      this.check_idx = -1
      //
      for(var i=0;i<this.order_list.length;i+=1){
        if(i==index){
          this.order_list[i].check = !this.order_list[i].check
          if(this.order_list[i].check) this.check_idx = i
        } else {
          this.order_list[i].check = false
        }
      }
      console.log("check",this.check_idx)
    },
    async creatQrCode() {
      if(this.check_idx<0){
        //Toast("请先选择购买套餐")
        return
      }
      this.qr_code = 0
      let order_type = this.order_list[this.check_idx].order_type
      await axios.get('https://caixiaowen.com/pay?order_type=' + order_type +'&user_id=' + this.userID)
        .then((result) => {
          let urlstr = JSON.parse(result.data.message).code_url
          console.log("creatQrCode",result.data)
          this.qr_code = 1
          new QRCode(this.$refs.qrCodeUrl, {
            text: urlstr,
            width: 150,
            height: 150,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
          })
        }).catch((error) => {
          console.log(error)
        })  
    },
    close(type){
      this.$emit('close', type)
    }
  }
}
</script>

<style scoped>
.horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
}
</style>
