import Vue from 'vue'
import Router from 'vue-router'

import HomePage from '@/components/HomePage'
import HomePage2 from '@/components2/HomePage2'
import TalkingFace from '@/components2/TalkingFace'
import TryOn from '@/components2/TryOn'
import StoryVideo from '@/components2/StoryVideo'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/homepage2',
      name: 'homepage2',
      component: HomePage2
    },
    {
      path: '/storyvideo',
      name: 'storyvideo',
      component: StoryVideo
    },
    {
      path: '/tryon',
      name: 'tryon',
      component: TryOn
    },
    {
      path: '/talkingface',
      name: 'talkingface',
      component: TalkingFace
    },
    {
      path: '/',
      name: 'homepage',
      component: HomePage
    },
  ],
  mode: "history"//干掉地址栏里边的#号键
})
