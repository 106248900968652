<template>
  <div style="height: 100vh;font-size: 16rem;position: relative;display: flex;flex-direction: column;">
    <div class="horizontal" style="padding:10rem;align-items: center;">
      <img style="display: block; width:50rem; height:50rem;" src="../assets/logo2.png">
      <!--<div style="font-size: 24rem;padding-left:10rem;">文铭数字人系统</div>-->
      <div v-if="userID.length>0" class="horizontal" style="position: absolute; right:10rem;align-items: center;">
        <span>Welcome, {{ userName }}</span>
        <div style="margin-left:10rem;text-align: center; background: #eeeeee;padding: 5rem 10rem;border-radius: 5rem;" @click="exit()">
          退出
        </div>
      </div>
    </div>

    <split-pane :min-percent="10" :default-percent="10" split="vertical" style="border: #999999 solid 1rem;flex: 1;">
      <template slot="paneL">
        <div class="horizontal" style="margin:20rem;font-size: 18rem;align-items: center;" @click="setWindowID(0)"
          :style="{ 'background-image': 'url(' + background2[0] + ')', 'background-size': '100% 100%'}">
          <img style="display: block;width: 30rem;height:30rem;margin-right: 10rem;" src="../assets/talkingface-icon1.png">
          我的虚拟人
        </div>
        <div class="horizontal" style="margin:20rem;font-size: 18rem;align-items: center;" @click="setWindowID(1)"
          :style="{ 'background-image': 'url(' + background2[1] + ')', 'background-size': '100% 100%'}">
          <img style="display: block;width: 30rem;height:30rem;margin-right: 10rem;" src="../assets/talkingface-icon2.png">
          我的视频
        </div>
        <div style="position: absolute; left:20rem;bottom:20rem;">
          <img style="display: block;width:105rem;height:35rem;margin-bottom: 10rem;" src="../assets/buy-button.png" @click="showBuyPanel=true">
          <span>剩余AI币:</span>
          <span style="color:red;font-weight: bold;">{{ aiMoney }}</span>
        </div>
      </template>
      <template slot="paneR">
        <div v-if="window_idx==0 && userID.length>0" class="horizontal" style="height:100%;overflow: auto;">
          <div style="border: #999999 solid 1rem;border-radius: 15rem;width:48%;max-height: 90%;overflow: auto;margin: 20rem 0 0 20rem;">
            <img v-if="ref_video_list.length>0 && ref_video_list[select_model_idx].system==0"
              style="float:right;display:block;width:30rem;height:30rem;margin:20rem 0;" src="../assets/talkingface-icon4.png"/>
            <div class="horizontal" style="overflow: auto;flex-wrap:nowrap;">
              <div v-for="(item, index) in ref_video_list" :key="index" style="margin: 15rem 5rem 0 10rem;" @click="confirmRefVideo(index)">
                <div style="padding: 8rem 12rem;background: #ffeffd;border-radius:8rem;font-style: italic;">
                  <span v-if="select_model_idx==index">虚拟人{{ 1 + index }}</span>
                  <span v-else style="font-size:12rem;color:#aaaaaa;">虚拟人{{ 1 + index }}</span>
                </div>
              </div>
            </div>
            <video-player class="video-player vjs-custom-skin"
              :playsinline="true" 
              :options="playerOptions2">
            </video-player>
          </div>
          <div style="width:48%;margin:20rem 0 0 20rem;">
            <div style="margin:10rem 0;font-style: italic;font-size:20rem;font-weight: bold;">换模特</div>
            <div class="horizontal" style="overflow: auto;flex-wrap:nowrap;">
              <div v-for="(item, index) in model_list" :key="index" style="margin-right: 20rem;">
                <img style="display: block;width:120rem;height:160rem;" v-bind:src="item.image_url">
                <div style="width:120rem;text-align: center;padding-top: 5rem;">{{ item.title }}</div>
              </div>
            </div>
            <div style="height: 1rem;background: #999999;margin:20rem 0;"></div>
            <div style="margin:10rem 0;font-style: italic;font-size:20rem;font-weight: bold;">换人脸</div>
            <div class="horizontal" style="overflow: auto;flex-wrap:nowrap;">
              <div v-for="(item, index) in face_list" :key="index" style="margin-right: 20rem;">
                <img style="display: block;width:160rem;height:160rem;" v-bind:src="item.image_url">
                <div style="width:160rem;text-align: center;padding-top: 5rem;">{{ item.title }}</div>
              </div>
            </div>
            <div style="height: 1rem;background: #999999;margin:20rem 0;"></div>
            <div style="margin:10rem 0;font-style: italic;font-size:20rem;font-weight: bold;">换衣服</div>
            <div class="horizontal" style="overflow: auto;flex-wrap:nowrap;">
              <div v-for="(item, index) in clothes_list" :key="index" style="margin-right: 20rem;">
                <img style="display: block;width:160rem;height:160rem;" v-bind:src="item.image_url">
                <div style="width:160rem;text-align: center;padding-top: 5rem;">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="window_idx==1" class="horizontal" style="overflow: auto;">
          <div v-for="(item, index) in video_list" :key="index" style="margin:20rem;">
            <div style="width:240rem;height:160rem;border-radius: 15rem;border: #999999 solid 1rem;background: #f6f6f6;position: relative;">
              <img style="display:block; height: 160rem;margin: auto;" v-bind:src="item.image_url" @click="playVideo(index)">
              <div v-if="index>0" style="position: absolute;left:0;top:0;right:0;bottom: 0; background: rgba(100,100,100,0.2);" @click="playVideo(index)">
                <img v-if="item.status==1" style="margin:60rem auto;display: block; width:40rem; height:40rem;" src="../assets/floatplay2.png">
                <img v-if="item.status==-1" class="waitingImg" src="../assets/ic_loading.png">
                <img v-if="item.status==0" style="margin:60rem auto;display: block; width:40rem; height:40rem;" src="../assets/talkingface-icon6.png">
              </div>
              <div v-if="index>0" style="position: absolute;right:0;bottom: 0;">
                <img v-if="item.status>=0" style="display:block;width:30rem;height:30rem;z-index: 999;" src="../assets/talkingface-icon4.png" @click="delVideo(index)">
                <img v-if="item.status==1" style="display:block;width:30rem;height:30rem;z-index: 999;" src="../assets/talkingface-icon5.png" @click="downVideo(index)">
                <div v-if="item.status==-1" style="padding:5rem;color:green;">约需{{ waiting_time }}秒</div>
              </div>
            </div>
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
        <div v-if="window_idx==2">
        </div>
      </template>
    </split-pane>

    <div v-if="window_idx==0"
      style="position:absolute;left:100rem;top:100rem;width: 60%;height: 60%;background: #B6D7FF;opacity: 0.3;filter: blur(300rem);z-index: -1;">
    </div>
    <div v-if="window_idx==1"
      style="position:absolute;left:200rem;top:200rem;width: 60%;height: 60%;background:  #779dcd;opacity: 0.3;filter: blur(300rem);z-index: -1;">
    </div>
    <div v-if="window_idx==2"
      style="position:absolute;left:300rem;top:300rem;width: 60%;height: 60%;background: #153964;opacity: 0.3;filter: blur(300rem);z-index: -1;">
    </div>

    <div class="mask" v-if="userID.length == 0">
      <LogIn @user_info="landing"/>
    </div>
    <div class="mask" v-else-if="video_idx == 0">
      <div style="background: #f6f6f6;border-radius: 15rem;width:700rem;font-size: 18rem;">
        <img style="float:right;padding: 10rem;display: block;width: 18rem;height: 18rem;" src="../assets/close_x.png" @click="video_idx = -1">
        <div class="horizontal" style="margin:30rem;overflow: auto;flex-wrap: nowrap;align-items: center;">
          <div style="margin-right: 20rem;">选择虚拟角色:</div>
          <div v-for="(item, index) in ref_video_list" :key="index" @click="select_model_idx=index">
            <div class="model_card" :style="{ 'border': select_model_idx == index ? 'gold solid 3rem' : '#eeeeee solid 3rem' }">
              <img style="display:block; height: 80rem;margin:0 auto;" v-bind:src="item.image_url">
            </div>
          </div>
        </div>
        <div class="horizontal" style="margin:0 30rem;">
          <div v-if="tts_mode" style="background: #715af6;color:white;padding:8rem 15rem; border-radius: 5rem;margin-right: 5rem;" @click="tts_mode=true">文字生成</div>
          <div v-else style="background: #dcdfee;padding:8rem 15rem; border-radius: 5rem;margin-right: 5rem;" @click="tts_mode=true">文字生成</div>

          <div v-if="!tts_mode" style="background: #715af6;color:white;padding:8rem 15rem; border-radius: 5rem;" @click="tts_mode=false">语音上传</div>
          <div v-else style="background: #dcdfee;padding:8rem 15rem; border-radius: 5rem;" @click="tts_mode=false">语音上传</div>
        </div>
        <div style="width:640rem;height:120rem;margin:0 30rem;background:#dddddd;">
          <textarea v-if="tts_mode" v-model="tts_text" style="width: 98%;height:120rem;background: #f6f6f6;" placeholder="声音合成功能没有完善,暂无法使用。"></textarea>
          <input v-else type="file" style="margin:45rem 10rem;" accept="audio/*" @change="checkAudioFile" />
        </div>
        <div class="horizontal" style="margin:30rem;align-items: center;">
          <div style="margin-right: 5rem;">选项设置</div>
          <img v-if="!showSettingPanel" style="width:15rem;height:10rem;" src="../assets/down_arrow.png"
            @click="showSettingPanel = !showSettingPanel">
          <img v-else style="width:15rem;height:10rem;" src="../assets/up_arrow.png"
            @click="showSettingPanel = !showSettingPanel">
        </div>
        <div v-if="showSettingPanel">
          <div style="color: #bbbbbb;text-align: center;font-size: 16rem;">选项设置功能暂未开放</div>
          <div style="height:1rem;margin:10rem;background: #999999;"></div>
        </div>

        <div style="text-align: center;font-size: 18rem;margin:10rem;">
          <span v-if="generate_money>0">预估生成此视频需要消耗 </span>
          <span v-if="generate_money>0" style="color: red;"> {{ generate_money }}</span>
          <span v-if="generate_money>0"> AI币</span>
          <span v-else style="color: #bbbbbb;font-size: 16rem;">{{generate_tips}}</span>
        </div>

        <div v-if="generate_status>=0" style="margin:10rem auto;width:120rem;text-align: center; background: #715af6;padding: 10rem;border-radius: 10rem;color:white;font-size: 20rem;" @click="generate">
          <span v-if="generate_status==0">确认</span>
          <span v-if="generate_status==1">开始生成</span>
        </div>
      </div>
    </div>
    <div class="mask" v-else-if="showPlayerPanel">
      <div style="background:white;border-radius: 15rem;">
        <img style="float:right;display:block;width:20rem;height:20rem;margin:10rem;" src="../assets/close_x.png" @click='closeVideoPanel'/>
        <div style="width: 400rem;height: auto;margin:35rem 10rem;">
          <video-player class="video-player vjs-custom-skin"
            :playsinline="true" 
            :options="playerOptions">
          </video-player>
        </div>
      </div>
    </div>
    <div class="mask" v-else-if="showBuyPanel">
      <PayFor :userID="userID" :aiMoney=aiMoney @close="closeBuyPanel"/>
    </div>
    <div class="mask" v-else-if="showDelPanel">
      <div style="background: #f6f6f6;border-radius: 15rem;padding: 25rem;font-size: 18rem;">
        <div style="color:#666666;padding-bottom: 10rem;">确定删除该视频吗?</div>
        <div class="horizontal">
          <div style="text-align: center; background: #666666;padding: 10rem 20rem;border-radius: 10rem;color:white;" @click="showDelPanel=false">取消</div>
          <div style="margin-left:20rem;text-align: center; background: #666666;padding: 10rem 20rem;border-radius: 10rem;color:white;" @click="confirmDel">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant'
import { generateRandomString,PLAY_OPTION } from '../components/util'
import LogIn from './LogIn.vue';
import PayFor from './PayFor.vue';
import { saveAs } from "file-saver";

export default {
  components: { LogIn, PayFor },
  data() {
    return {
      userID: "",
      userName: "",
      aiMoney: "0.0",

      window_idx:0,
      background2:[],

      ref_video_list:[],
      model_list:[],
      face_list:[],
      clothes_list:[],

      select_model_idx:0,
      tts_text:"",
      tts_mode:false,

      video_list: [{
        "image_url": require("../assets/add_pic.png")
      }],

      loading: false,
      generate_status:0,
      generate_money:0,
      generate_tips: "",
      waiting_time: -1,
      waiting_num: -1,
      
      select_audio_file:null,
      
      video_idx:-1,
      showPlayerPanel:false,
      showSettingPanel: false,
      showBuyPanel: false,

      showDelPanel: false,
      del_index:-1,

      playerOptions : null,
      playerOptions2: null
    }
  },

  created() {
    document.title = ''
    this.getRouterData()
  },
  destroyed() {
    //
  },
  methods: {
    getRouterData() {
      this.playerOptions = structuredClone(PLAY_OPTION)
      //
      this.playerOptions2 = structuredClone(PLAY_OPTION)
      this.playerOptions2.controls = false
      this.playerOptions2.loop = true
      //
      this.setWindowID(0)

      let userid = localStorage.getItem("user_id")
      let username = localStorage.getItem("user_name")
      if (userid != null && userid.length>0) {
        this.userID = userid
        this.userName = username

        this.loadVideoList()
        this.orderInfo()
      }
    },
    setWindowID(index){
      this.window_idx = index
      this.background2 = [
        require('../assets/background2-0.png'),
        require('../assets/background2-0.png'),
        require('../assets/background2-0.png')
      ]
      this.background2[index] = require('../assets/background2-1.png')
    },
    async loadVideoList() {
      if (this.loading) return
      this.loading = true

      let request_data = {
        'type': 'talkingface_video_list',
        'data': {
          'user_id': this.userID
        }
      }
      await axios.post('https://caixiaowen.com/zsp', JSON.stringify(request_data))
        .then((result) => {
          console.log(result.data)

          this.ref_video_list = result.data.ref_video_list
          this.model_list = result.data.model_list
          this.face_list = result.data.face_list
          this.clothes_list = result.data.clothes_list

          this.confirmRefVideo(0)

          this.video_list = result.data.video_list
          this.video_list.unshift({
            "image_url": require("../assets/add_pic.png")
          })
          this.loading = false
          this.checkResult()
        }).catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    checkResult() {
      let sid = this.waitingSid()
      if(sid == "") return

      let request_data = {
        'type': 'talkingface_check_result',
        'data': {
          'user_id': this.userID,
          'sid': sid
        }
      }
      axios.post('https://caixiaowen.com/zsp', JSON.stringify(request_data))
        .then((result) => {
          console.log(result.data)
          if(result.data.err_no==0){
            for(var i = 1;i<this.video_list.length;i+=1){
              if(this.video_list[i].sid==sid){
                this.video_list[i]=result.data
                this.waiting_num = -1
                this.waiting_time = -1
                console.log("check result success")
                this.orderInfo()
                return
              }
            }
          } else if(result.data.err_no==1){
            this.waiting_time = result.data.waiting_time
            this.waiting_num = result.data.waiting_num
            var that = this
            setTimeout(function () {
              that.checkResult()
            }, that.waiting_time*1000)
          }
        }).catch((error) => {
          console.log(error)
        })
    },
    confirmRefVideo(index){
      this.select_model_idx = index
      this.playerOptions2.sources=[{
        type: "video/mp4",
        src: this.ref_video_list[index].video_url
      }]
    },
    orderInfo(){
      let request_data = {
        'type': 'order_info',
        'data': {
          'user_id': this.userID,
          'album_id': 'AI00'
        }
      }
      axios.post('https://caixiaowen.com/tongtu', JSON.stringify(request_data))
        .then((result) => {
          console.log(result.data)
          if(result.data.order_list.length>0){
            let t = JSON.parse(result.data.order_list[0])
            this.aiMoney = "" + t.ai_money
          }
        }).catch((error) => {
          console.log(error)
        })
    },
    closeBuyPanel(message){
      this.showBuyPanel=false
      if(message==1) this.orderInfo()
    },
    exit(){
      this.userID = ""
      this.userName = ""
      //
      localStorage.removeItem("user_id")
      localStorage.removeItem("user_name")
      localStorage.removeItem("phone_num")
    },
    landing(message){
      //console.log("message",message)
      this.userID = "" + message.user_id
      this.userName = message.user_name

      if(this.userID.length>0){
        this.loadVideoList()
        this.orderInfo()
      }
    },
    checkAudioFile(event) {
      if(event.target.files.length>0){
        this.select_audio_file = event.target.files[0]
        console.log(this.select_audio_file.name)
      }
    },
    waitingSid(){
      for(var i = 1;i<this.video_list.length;i+=1){
        if(this.video_list[i].status==-1) return this.video_list[i].sid
      }
      return ""
    },
    playVideo(index) {
      if(index == 0){
        if(this.waitingSid()==""){
          this.video_idx = index
          this.generate_status = 0
          this.generate_money = 0
          this.generate_tips = ""
          this.select_audio_file = null
        } else {
          Toast("有任务未完成, 请稍等哦")
        }
        return
      }
    
      this.video_idx = index
      if(this.video_list[index].status==1) {
        this.showPlayerPanel = true
        this.playerOptions.sources=[{
          type: "video/mp4",
          src: this.video_list[index].video_url
        }]
      } else if(this.video_list[index].status==0) {
        Toast("生成失败了, 请检查上传内容, 或联系客服处理")
      } else if(this.video_list[index].status==-1) {
        /*
        if(this.waiting_num<0){
          Toast("正在查询需要的时间")
        } else if(this.waiting_num==0){
          Toast("正在生成中,预计需要"+this.waiting_time+"秒")
        } else {
          Toast("正在排队中,预计需要"+this.waiting_time+"秒")
        }
        */
      }
    },
    delVideo(index){
      this.del_index = index
      this.showDelPanel = true
    },
    confirmDel(){
      let request_data = {
        'type': 'talkingface_update_result',
        'data': {
          'user_id': this.userID,
          'sid': this.video_list[this.del_index].sid,
          'del': 1
        }
      }
      axios.post('https://caixiaowen.com/zsp', JSON.stringify(request_data))
      .then((result) => {
        console.log(result.data)
        this.video_list.splice(this.del_index, 1)
        this.showDelPanel = false
      }).catch((error) => {
        console.log(error)
      })
    },
    downVideo(index) {
      fetch(this.video_list[index].video_url)
        .then(response => response.blob())
        .then(blob => {
          saveAs(blob, "download.mp4");
        });
    },
    closeVideoPanel(){
      this.video_idx = -1
      this.showPlayerPanel = false
    },
    async generate(){
      if(this.select_audio_file==null){
        this.generate_tips = "请先选择音频"
        return
      }

      if(this.select_audio_file.size > 10 * 1024 * 1024) {
        this.generate_tips = "文件限制,超过了10M"
        return
      }

      if(this.generate_status==0){
        const audio = new Audio();
        audio.src = URL.createObjectURL(this.select_audio_file)
        audio.onloadedmetadata = () => {
          console.log(audio.duration)
          if(audio.duration>120){
            this.generate_tips = "文件限制,超过了2分钟,请联系客服定制"
          } else {
            this.generate_money = ((audio.duration/30)*0.5).toFixed(1)
            if(this.generate_money<0.5) this.generate_money = 0.5
            if(this.generate_money>parseFloat(this.aiMoney)){
              this.generate_money = 0
              this.generate_tips = "余额不足,请先充值"
            } else{
              this.generate_tips = ""
              this.generate_status = 1
            }
          }
        };
        return
      }

      this.generate_tips = "上传文件..." + this.select_audio_file.name
      this.generate_money = 0
      this.generate_status = -1
      var audio_url = ""
      var audio_local = ""
      //
      const formData2 = new FormData();
      formData2.append("file", this.select_audio_file)
      await axios
        .post("https://caixiaowen.com/zsp", formData2, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data)
          if(response.data.err_no==0){
            audio_url = response.data.file_url
            audio_local = response.data.file_local
          }
        })
        .catch((error) => {
          console.error(error)   
        })
      if(this.audio_url==""){
        this.generate_tips = "上传失败: " + this.select_audio_file.name
        this.generate_status = 1
        return
      }
      //
      const now = new Date()
      let title = now.toLocaleString()
      console.log("title",title)

      this.generate_tips = "请求视频生成..."
      let sid = generateRandomString(10)
      let request_data = {
        'type': 'talkingface_video_generate',
        'data': {
          'user_id': this.userID,
          'title':title,
          'sid': sid,
          'audio_url': audio_url,
          'audio_local': audio_local,
          'image_url': this.ref_video_list[this.select_model_idx].image_url,
          'face_id':this.ref_video_list[this.select_model_idx].face_id
        }
      }
      axios.post('https://caixiaowen.com/zsp', JSON.stringify(request_data))
        .then((result) => {
          console.log(result.data)
          if(result.data.err_no==0){
            this.video_list.push(result.data)
            this.generate_tips = "正在生成中,需要一些时间,该窗口5秒后自动关闭"
            var that = this
            setTimeout(function () {
              that.video_idx = -1
              that.checkResult(sid)
            }, 5000)
          } else {
            this.generate_tips = "请求生成失败"
            this.generate_status = 1
          }
        }).catch((error) => {
          console.log(error)
          this.generate_tips = "请求生成失败"
          this.generate_status = 1
        })
    }
  }
}
</script>

<style scoped>
.horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: flex-start;
}

.title {
  text-align: center;
  margin-top: 10rem;
  padding: 0 10rem;
  font-size: 18rem;
  width: 220rem;
  color: #222222;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.waitingImg {
  display: block;
  width: 40rem;
  height: 40rem;
  margin:60rem auto;
  animation: rotateImg 8s linear infinite;
}
@keyframes rotateImg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.model_card {
  margin-right:15rem;
  border-radius: 15rem;
  background: #f6f6f6;
  width:120rem;
  height:80rem;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .3);
  z-index: 9999;

  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}</style>