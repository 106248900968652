<template>
  <div class="" id="homepage">
    <div style="position:absolute;left:0;top:0;z-index: 1">
      <div class="head" :style="{ 'color': titlecolor[pageId] }">
        <img style="display: block; width:50rem; height:50rem;padding-left: 375rem;padding-right: 590rem;"
          src="../assets/logo.png">
        <div v-for="(item, index) in titlename" :key="index">
          <div v-if="(index == pageId)" style="padding-left:40rem; opacity: 1.0;" @click="gotoPage(index)">{{ item }}
          </div>
          <div v-else style="padding-left:40rem; opacity: 0.7;" @click="gotoPage(index)">{{ item }}</div>
        </div>
      </div>
    </div>

    <div v-if="(pageId == 0)"><PageHome></PageHome></div>
    <div v-if="(pageId == 2)"><PageDownload></PageDownload></div>
    <div v-if="(pageId == 3)"><PageAbout></PageAbout></div>

    <div v-if="(pageId == 0 || pageId == 3)" class="contactus">
      <div class="horizontal" style="padding-left:375rem;padding-top: 60rem;align-items: center;">
        <div style="width:670rem;">
          <div style="color:white;font-size:36rem;padding-bottom: 30rem;">
            珍惜你的每一分钟，助力企业国际沟通！
          </div>
          <div style="color:white;font-size:18rem;opacity: 0.7;line-height: 30rem;">
            通途AI英语不仅是语言方案服务商，更是AI交互技术服务商，提供更具价值的专业内容定制、AI训练定制，与企业学习平台技术拓展服务。<br>通途AI英语，新一代职场英语与国际化人才解决方案服务商，首创5M训练体系，一站式解决职场英语三大痛点，为中国企业国际化保驾护航！
          </div>
        </div>
        <div style="background:white;width: 370rem;height:440rem;margin-left: 130rem;">
          <div style="color:#283342;font-size:20rem;text-align: center;padding-top: 30rem;">
            联系我们
          </div>
          <div style="padding:30rem 30rem 10rem 30rem;">
            <textarea style="width:290rem;height:20rem;padding:10rem;font-size:16rem;color:#283342;opacity: 0.5;"
              placeholder="企业名称*" wrap="off"></textarea>
          </div>
          <div class="horizontal">
            <div style="padding:10rem 30rem 10rem 30rem;">
              <textarea style="width:120rem;height:20rem;padding:10rem;font-size:16rem;color:#283342;opacity: 0.5;"
                placeholder="姓名*" wrap="off"></textarea>
            </div>
            <div style="padding:10rem 30rem 10rem 0rem;">
              <textarea style="width:120rem;height:20rem;padding:10rem;font-size:16rem;color:#283342;opacity: 0.5;"
                placeholder="手机号*" wrap="off"></textarea>
            </div>
          </div>
          <div style="padding:10rem 30rem 10rem 30rem;">
            <textarea style="width:290rem;height:20rem;padding:10rem;font-size:16rem;color:#283342;opacity: 0.5;"
              placeholder="邮箱*" wrap="off"></textarea>
          </div>
          <div style="padding:10rem 30rem 10rem 30rem;">
            <textarea style="width:290rem;height:62rem;padding:10rem;font-size:16rem;color:#283342;opacity: 0.5;"
              placeholder="留言需求*" wrap="on"></textarea>
          </div>
          <div
            style="margin:10rem 30rem 20rem 30rem;width:310rem;height: 40rem;background: #3988FF;opacity: 0.5; text-align: center;border-radius: 10rem;">
            <div style="color:white;font-size: 16rem;padding-top: 10rem;">提交</div>
          </div>
        </div>
      </div>
    </div>

    <div style="width:1920rem;height:240rem; background:#12151A">
      <div class="horizontal" style="padding-left:375rem;padding-top: 48rem;">
        <img style="display: block;width:73rem;height: 30rem;" src="../assets/tongtu_bottom.png">
        <div style="padding-left:84rem;">
          <div style="font-size:24rem;color: white;">联系方式</div>
          <div style="padding-top:20rem;font-size:18rem;color: white;opacity: 0.5;">
            电话：15210571256<br>邮箱：lianyang@tingtingyingyu.com<br>地址：北京市大兴区榆顺路12号D座0562号
          </div>
          <div style="padding-top:10rem;"><a style="color: white;font-size:18rem;" href="https://beian.miit.gov.cn/" target="_blank">京ICP备2021039423号-2</a></div>
        </div>
        <div style="padding-left:500rem;">
          <img style="display:block;width:100rem;height:100rem;" src="../assets/kefuerweima.png">
          <div style="padding-top:10rem;font-size:14rem;color: white;opacity: 0.5;line-height: 16rem;">
            扫描二维码<br>添加通途英语连老师
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import PageHome from './PageHome.vue'
import PageDownload from './PageDownload.vue'
import PageAbout from './PageAbout.vue'

export default {
  components: { PageHome, PageDownload, PageAbout },
  data() {
    return {
      pageId: 0,
      titlecolor: ["rgb(255,255,255)", "", "rgb(40,51,66)", "rgb(40,51,66)"],
      titlename: ["首页", "1对1外教课", "App下载", "关于我们"]
    }
  },

  created() {
    console.log("route", window.location.href)
    document.title = '职场英语 | 北京叔牙信息科技有限公司'
  },

  methods: {
    gotoPage(index) {
      console.log("gotoPage ", index)
      if (index == 1) {
        window.location.href = "https://www.cambly.com/student/login"
      }
      else {
        this.pageId = index
      }
    }
  },
}
</script>

<style scoped>
#homepage .head {
  flex-direction: row;
  display: flex;
  position: relative;
  padding-top: 24rem;
  color: white;
  font-size: 18rem;
}

#homepage .horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
}

#homepage .contactus {
  width: 1920rem;
  height: 560rem;
  background: url('../assets/contact_us.png');
  background-size: 100% 100%;
}
</style>
