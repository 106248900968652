<template>
  <div style="height: 100vh;font-size: 16rem;position: relative;display: flex;flex-direction: column;">
    <div class="horizontal" style="padding:10rem;align-items: center;">
      <img style="display: block; width:60rem; height:60rem;" src="../assets/logo2.png">
      <div style="font-weight: bold;font-size: 30rem;padding-left:15rem;">AI model</div>
      <!--
      <div
        style="position: absolute; right:10rem;background: #222222; border-radius: 25rem;padding:10rem 15rem;color: yellow;font-size: 20rem;"
        @click="buy">
        购买AI币
      </div>
      -->
    </div>

    <split-pane :min-percent="6" :default-percent="18" split="vertical" @resize="resizeSplitPanel" style="border: #bbbbbb solid 1rem;flex: 1;">
      <template slot="paneL">
        <div style="height:100%;overflow: auto;">
          <!---->
          <div class="horizontal" style="margin:30rem 0 5rem 15rem;align-items: center;">
            <div style="margin-right: 5rem;font-size: 18rem;font-weight: 500;">选择模特</div>
            <img v-if="!showModelPanel" style="width:15rem;height:10rem;" src="../assets/down_arrow.png"
              @click="showModelPanel = !showModelPanel">
            <img v-else style="width:15rem;height:10rem;" src="../assets/up_arrow.png"
              @click="showModelPanel = !showModelPanel">
          </div>
          <div v-if="showModelPanel" class="horizontal" style="overflow: auto;max-height: 420rem;">
            <div v-for="(item, index) in model_list" :key="index">
              <div v-if="index<3 || index==model_list.length-1" style="margin:15rem;">
                <img style="display:block; width:140rem;height: 140rem;"
                :class = "{ 'selected_border': select_model_idx==index}"
                  v-bind:src="item.image_url" @click="selectImg(0,index)">
                <div class="title">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <!---->
          <div class="horizontal" style="margin:30rem 0 5rem 15rem;align-items: center;">
            <div style="margin-right: 5rem;font-size: 18rem;font-weight: 500;">选择场景</div>
            <img v-if="!showScenePanel" style="width:15rem;height:10rem;" src="../assets/down_arrow.png"
              @click="showScenePanel = !showScenePanel">
            <img v-else style="width:15rem;height:10rem;" src="../assets/up_arrow.png"
              @click="showScenePanel = !showScenePanel">
          </div>
          <div v-if="showScenePanel" class="horizontal" style="overflow: auto;max-height: 420rem;">
            <div v-for="(item, index) in scene_list" :key="index">
              <div v-if="index<3 || index==scene_list.length-1" style="margin:15rem;">
                <img style="display:block; width:140rem;height: 140rem;"
                  :class = "{ 'selected_border': select_scene_idx==index}"
                  v-bind:src="item.image_url" @click="selectImg(1,index)">
                <div class="title">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <!---->
          <div class="horizontal" style="margin:30rem 0 5rem 15rem;align-items: center;">
            <div style="margin-right: 5rem;font-size: 18rem;font-weight: 500;">历史记录</div>
            <img v-if="!showHistoryPanel" style="width:15rem;height:10rem;" src="../assets/down_arrow.png"
              @click="showHistoryPanel = !showHistoryPanel">
            <img v-else style="width:15rem;height:10rem;" src="../assets/up_arrow.png"
              @click="showHistoryPanel = !showHistoryPanel">
          </div>
          <div v-if="showHistoryPanel" class="horizontal" style="overflow: auto;max-height: 800rem;">
            <div v-for="(item, index) in history_list" :key="index" style="margin:15rem;">
              <img style="display:block; width:140rem;height: 140rem;border: #f6f6f6 solid 1rem;" v-bind:src="item.image_url" @click="selectImg(2,index)">
              <div class="title">{{ item.title }}</div>
            </div>
            <div v-if="history_list.length==0" style="color: #bbbbbb;font-size: 18rem;margin:15rem;">没有历史记录</div>
          </div>
          <!---->
        </div>
      </template>
      <template slot="paneR">
        <div style="display: flex;justify-content: center;align-items: center;height: 80vh;">
          <div style="background: #eeeeee;border-radius: 15rem;padding: 25rem;">
            <div style="font-size: 18rem;margin-bottom: 15rem;font-weight: bold;">上传图片</div>
            <input type="file" style="height:40rem;" accept="image/*" @change="checkFile"/>
            <div style="line-height: 25rem;margin:15rem 0;">
              <span style="font-weight: bold;">要求:<br></span>1. 大小不超过10M<br>2. 高宽比范围0.5~2<br>3. 支持JPG,PNG图片格式
            </div>
            <div style="margin:0 auto;width:50rem;text-align: center; background: #666666;padding: 10rem;border-radius: 10rem;color:white;" @click="imageMatting">
              确认
            </div>
          </div>
        </div>
      </template>
    </split-pane>

    <div style="position:absolute;left:200rem;top:200rem;width: 60%;height: 60%;background: #B6D7FF;opacity: 0.3;filter: blur(300rem);z-index: -1;"></div>

    <div class="mask" v-if="userID.length == 0">
      <div class="horizontal" style="background: #f6f6f6;border-radius: 15rem;padding: 25rem;align-items: center;">
        <input style="font-size: 18rem;border-style: dotted;width:200rem;height: 30rem;padding:5rem;" placeholder="请输入邀请码">
        <div style="margin-left:10rem;text-align: center; background: #666666;padding: 10rem 20rem;border-radius: 10rem;color:white;">确认</div>
      </div>
    </div>
    <div class="mask" v-else-if="showModelMorePanel">
      <img style="padding: 10rem;display: block;width: 18rem;height: 18rem;" src="../assets/close_x.png" @click="close">
      <div class="horizontal" style="overflow: auto;width:700rem;max-height:80vh;background: #f6f6f6;border-radius: 15rem;padding: 25rem;">
        <div v-for="(item, index) in model_list" :key="index">
          <div v-if="index<model_list.length-1" style="margin:15rem;">
            <img style="display:block; width:200rem;height: 200rem;"
              :class = "{ 'selected_border': select_model_idx==index}"
              v-bind:src="item.image_url" @click="selectImg(0,index)">
            <div class="title" style="width:180rem;">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mask" v-else-if="showSceneMorePanel">
      <img style="padding: 10rem;display: block;width: 18rem;height: 18rem;" src="../assets/close_x.png" @click="close">
      <div class="horizontal" style="overflow: auto;width:700rem;max-height:80vh;background: #f6f6f6;border-radius: 15rem;padding: 25rem;">
        <div v-for="(item, index) in scene_list" :key="index">
          <div v-if="index<scene_list.length-1" style="margin:15rem;">
            <img style="display:block; width:200rem;height: 200rem;"
              :class = "{ 'selected_border': select_scene_idx==index}"
              v-bind:src="item.image_url" @click="selectImg(1,index)">
            <div class="title" style="width:180rem;">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mask" v-else-if="showMattingPanel">
      <div style="width:65%;height:80vh;background: #f6f6f6;border-radius: 15rem;padding: 25rem;">
        <div class="horizontal" style="align-items: center;margin-bottom: 15rem;">
          <div style="margin:10rem 20rem;" @click="mattingAdd">
            <img style="display: block; width:32rem; height:32rem;" src="../assets/matting-baoliu.png">
            <div style="font-size: 16rem;padding-top:5rem;">保留</div>
          </div>
          <div style="margin:10rem 20rem;" @click="mattingReduce">
            <img style="display: block; width:32rem; height:32rem;" src="../assets/matting-quchu.png">
            <div style="font-size: 16rem;padding-top:5rem;">去除</div>
          </div>
          <div style="margin:10rem 20rem;" @click="mattingRedo">
            <img style="display: block; width:32rem; height:32rem;" src="../assets/matting-chongzuo.png">
            <div style="font-size: 16rem;padding-top:5rem;">重做</div>
          </div>

          <div style="position: absolute; right:140rem; background: #999999; border-radius: 5rem;padding:8rem 20rem;font-size: 16rem;color:white;" @click="mattingFinish">
            完成抠图
          </div>
          <div style="position: absolute; right:30rem; border: #666666 solid 1rem; border-radius: 5rem;padding:8rem 20rem;font-size: 16rem;" @click="showMattingPanel=false">
            取消
          </div>
        </div>

        <div style="height:68vh;">
          <split-pane :min-percent="20" :default-percent="50" split="vertical">
            <template slot="paneL">
              <div style="border: #bbbbbb solid 1rem;padding:15rem;height:68vh;overflow: auto;">
                <canvas style="width:100%;" id="canvas" @click='mouseClick1'></canvas>
              </div>
            </template>
            <template slot="paneR">
              <div style="border: #bbbbbb solid 1rem;padding:15rem;height:68vh;overflow: auto;">
                <canvas style="width:100%;" id="canvas2"></canvas>
              </div>
            </template>
          </split-pane>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant'
import { generateRandomString} from '../components/util'

export default {
  components: {},
  data() {
    return {
      userID: "10000001",
      userName: "Jack",

      model_list:[],
      scene_list:[],
      history_list:[],
      showModelPanel:true,
      showScenePanel:true,
      showHistoryPanel:false,

      select_model_idx:0,
      select_scene_idx:-1,
      select_history_idx:-1,

      showModelMorePanel:false,
      showSceneMorePanel:false,
      //
      showMattingPanel:false,

      image_file:null,
      image_url:"",

      imgObjL :null,
      imgObjR :null,
      mask_i:-1,
      mask_i_select:[],

      imgObjOri:null,
      imgObjOri2:null,
      maskObjList:[],
      maskObjColorList:[],

      loading: false
    }
  },

  created() {
    document.title = ''
    this.getRouterData()
  },
  destroyed() {
    //
  },
  methods: {
    getRouterData() {
      if(this.userID.length>0){
        this.loadImageList()
      }
    },
    async loadImageList() {
      if (this.loading) return
      this.loading = true

      let request_data = {
        'type': 'tryon_image_list',
        'data': {
          'user_id': this.userID
        }
      }
      await axios.post('https://caixiaowen.com/zsp', JSON.stringify(request_data))
        .then((result) => {
          console.log(result.data)

          this.model_list = result.data.model_list
          this.model_list.push({
            "image_url": require("../assets/show_more.png")
          })

          this.scene_list = result.data.scene_list
          this.scene_list.push({
            "image_url": require("../assets/show_more.png")
          })

          this.history_list = result.data.history_list
          
          this.loading = false
        }).catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    buy() {
      Toast("购买功能暂未开放")
    },
    checkFile(event) {
      if(event.target.files.length>0){
        this.image_file = event.target.files[0]
        console.log(this.image_file.name)
      }
    },
    waitingSid(){
      for(var i = 1;i<this.history_list.length;i+=1){
        if(this.history_list[i].status==-1) return this.history_list[i].sid
      }
      return ""
    },

    selectImg(type,index){
      if(type==0){
        if(index==this.model_list.length-1){
          this.showModelMorePanel = true
        } else if(this.select_model_idx == index){
          this.select_model_idx = -1
        } else {
          this.select_model_idx = index
        }
      } else if(type==1){
        if(index==this.scene_list.length-1){
          this.showSceneMorePanel = true
        } else if(this.select_scene_idx == index){
          this.select_scene_idx = -1
        } else {
          this.select_scene_idx = index
        }
      } else {
        //
      }
    },
    
    resizeSplitPanel(){
      //
    },
    close(){
      this.showModelMorePanel = false
      this.showSceneMorePanel = false
      //
    },
    mattingAdd(){
      if(this.mask_i<0) return

      for(var i=0;i<this.mask_i_select.length;i+=1){
        if(this.mask_i_select[i]==this.mask_i) return
      }
      this.mask_i_select.push(this.mask_i)
      this.drawMask2()
    },
    mattingReduce(){
      if(this.mask_i<0) return

      for(var i=0;i<this.mask_i_select.length;i+=1){
        if(this.mask_i_select[i]==this.mask_i){
          this.mask_i_select.splice(i,1)
          this.drawMask2()
          return
        }
      }
    },
    mattingRedo(){
      this.mask_i_select = []
      this.drawMask2()
    },
    drawMask2(){
      for(var k=0;k<this.imgObjOri.data.length;k+=4){
        let ok = false
        for(var i=0;i<this.mask_i_select.length;i+=1){
          if(this.maskObjList[this.mask_i_select[i]].data[k]>0){
            ok=true
            break
          }
        }
        if(ok){
          this.imgObjR.data[k] = this.imgObjOri.data[k]
          this.imgObjR.data[k+1] = this.imgObjOri.data[k+1]
          this.imgObjR.data[k+2] = this.imgObjOri.data[k+2]
          this.imgObjR.data[k+3] = this.imgObjOri.data[k+3]
        } else {
          this.imgObjR.data[k] = 0
          this.imgObjR.data[k+1] = 0
          this.imgObjR.data[k+2] = 0
          this.imgObjR.data[k+3] = 32
        }
      }
      let canvasEle = document.getElementById("canvas2")
      let canvasCtx = canvasEle.getContext("2d")
      canvasCtx.putImageData(this.imgObjR,0,0)
    },
    drawMask(i){
      for(var k=0;k<this.imgObjOri.data.length;k+=4){
        if(i>=0 && this.maskObjList[i].data[k]>0){
          this.imgObjL.data[k] = this.imgObjOri.data[k]
          this.imgObjL.data[k+1] = this.imgObjOri.data[k+1]
          this.imgObjL.data[k+2] = this.imgObjOri.data[k+2]
          this.imgObjL.data[k+3] = this.imgObjOri.data[k+3]
        } else {
          this.imgObjL.data[k] = this.imgObjOri2.data[k]
          this.imgObjL.data[k+1] = this.imgObjOri2.data[k+1]
          this.imgObjL.data[k+2] = this.imgObjOri2.data[k+2]
          this.imgObjL.data[k+3] = this.imgObjOri2.data[k+3]
        }
      }
      this.mask_i = i
      //
      let canvasEle = document.getElementById("canvas")
      let canvasCtx = canvasEle.getContext("2d")
      canvasCtx.putImageData(this.imgObjL,0,0)
    },
    mouseClick1(e){
      if(this.maskObjList.length<=0) return

      let canvasEle = document.getElementById("canvas")
      let moverate=1.0*canvasEle.width/canvasEle.clientWidth

      var rect = canvasEle.getBoundingClientRect()
      var x = (e.clientX-rect.left) * moverate
      var y = (e.clientY-rect.top) * moverate
      x=parseInt(x)
      y=parseInt(y)
      console.log(x,y)
      if(x<0 || y<0 || x>=canvasEle.width || y>=canvasEle.height) return

      let pos = (x+y*canvasEle.width)*4
      for(var i=0;i<this.maskObjList.length;i+=1){
        let ii = this.mask_i + 1 + i
        if(this.maskObjList[ii%this.maskObjList.length].data[pos]>0){
          ii = ii%this.maskObjList.length
          if(ii!=this.mask_i) this.drawMask(ii)
          break
        }
      }
      if(i==this.maskObjList.length && this.mask_i>=0) this.drawMask(-1) 
    },
    readImage(tlist,i){
      let canvasEle = document.getElementById("canvas")
      let canvasCtx = canvasEle.getContext("2d")

      var tImg=new Image()
      tImg.crossOrigin = "anonymous"
      tImg.onload= ()=> {
        canvasEle.width=tImg.width
        canvasEle.height=tImg.height
        canvasCtx.drawImage(tImg, 0, 0)
        try{
          if(i==tlist.length-1){
            this.imgObjOri = canvasCtx.getImageData(0,0,canvasEle.width,canvasEle.height)
            console.log("last loading")

            this.imgObjL = new ImageData(tImg.width,tImg.height)
            this.imgObjOri2 = new ImageData(tImg.width,tImg.height)
            for(var k=0;k<this.imgObjOri.data.length;k+=4){
              this.imgObjOri2.data[k] = this.imgObjOri.data[k]
              this.imgObjOri2.data[k+1] = this.imgObjOri.data[k+1]
              this.imgObjOri2.data[k+2] = this.imgObjOri.data[k+2]
              this.imgObjOri2.data[k+3] = this.imgObjOri.data[k+3]
              for(var ii=0;ii<this.maskObjList.length;ii+=1){
                if(this.maskObjList[ii].data[k]>0){
                  this.imgObjOri2.data[k] = Math.min(parseInt(this.imgObjOri2.data[k]*0.4+this.maskObjColorList[ii][0]*0.6),255)
                  this.imgObjOri2.data[k+1] = Math.min(parseInt(this.imgObjOri2.data[k+1]*0.4+this.maskObjColorList[ii][1]*0.6),255)
                  this.imgObjOri2.data[k+2] = Math.min(parseInt(this.imgObjOri2.data[k+2]*0.4+this.maskObjColorList[ii][2]*0.6),255)
                }
              }
            }
            this.drawMask(-1)
            //
            this.imgObjR = new ImageData(tImg.width,tImg.height)
            for(k=0;k<this.imgObjR.data.length;k+=4){
              this.imgObjR.data[k] = 0
              this.imgObjR.data[k+1] = 0
              this.imgObjR.data[k+2] = 0
              this.imgObjR.data[k+3] = 32
              //
              this.imgObjL.data[k+1] = this.imgObjOri.data[k]
              this.imgObjL.data[k+2] = this.imgObjOri.data[k+1]
              this.imgObjL.data[k+3] = this.imgObjOri.data[k+2]
              this.imgObjL.data[k+4] = this.imgObjOri.data[k+3]
            }
            let canvasEle2 = document.getElementById("canvas2")
            let canvasCtx2 = canvasEle2.getContext("2d")
            canvasEle2.width=tImg.width
            canvasEle2.height=tImg.height
            canvasCtx2.putImageData(this.imgObjR,0,0)
          } else {
            this.maskObjList[i] = canvasCtx.getImageData(0,0,canvasEle.width,canvasEle.height)
            let red = 100+parseInt(Math.floor(Math.random()*100))
            let green = 100+parseInt(Math.floor(Math.random()*100))
            let blue = 100+parseInt(Math.floor(Math.random()*100))
            this.maskObjColorList[i] = [red,green,blue,100]

            this.readImage(tlist,i+1)
          }
        }
        catch (err) {
          Toast('读图片数据错误:' + err)
        }
      }
      tImg.onerror= (err) => {
        Toast('图片加载错误' + err)
      }
      tImg.src=tlist[i]
    },

    async imageMatting(){
      if(this.image_file==null){
        Toast("请先选择图片")
        return
      }
      this.image_url = ""

      const formData = new FormData();
      formData.append("file", this.image_file)
      await axios
        .post("https://caixiaowen.com/zsp", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data)
          if(response.data.err_no==0) this.image_url = response.data.filepath
        })
        .catch((error) => {
          console.error(error)   
        })
      if(this.image_url==""){
        Toast("图片上传失败")
        return
      }

      this.showMattingPanel = true

      let request_data = {
        'type': 'tryon_image_matting',
        'data': {
          'user_id': this.userID,
          'sid': generateRandomString(10),
          'image_url': this.image_url
        }
      }
      axios.post('https://caixiaowen.com/zsp', JSON.stringify(request_data))
        .then((result) => {
          console.log(result.data)
          if(result.data.err_no==0){
            this.image_url = result.data.image_url

            let tlist = result.data.mask_image_url_list
            tlist.push(this.image_url)
            this.readImage(tlist,0)
          } else {
            Toast("抠图失败")
            //
          }
        }).catch((error) => {
          console.log(error)
          Toast("抠图失败")
        })
    },
    mattingFinish(){
      let canvasEle2 = document.getElementById("canvas2")
      const dataURL = canvasEle2.toDataURL("image/png")
      // 创建一个链接元素
      const a = document.createElement("a")
      a.href = dataURL;
      a.download = "canvas_image.png"
      // 模拟点击下载链接
      a.click()
    }
  }
}
</script>

<style scoped>
.horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: flex-start;
}

.title {
  text-align: center;
  margin-top: 10rem;
  padding: 0 10rem;
  font-size: 16rem;
  width: 120rem;
  color: #222222;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.waitingImg {
  display: block;
  width: 20rem;
  height: 20rem;
  animation: rotateImg 8s linear infinite;
}
@keyframes rotateImg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.selected_border {
  border: red solid 2rem;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .3);
  z-index: 9999;

  width: 100%;
  height: 100%vh;

  display: flex;
  align-items: center;
  justify-content: center;
}</style>