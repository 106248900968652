<template>
  <div class="" id="pagedownload">
    <div style="padding-top:150rem;text-align: center;color:#283342;font-size: 48rem;">
      App下载
    </div>
    <div style="padding-top:30rem;text-align: center;color:#6C7077;font-size: 20rem;">
      通途英语AI课，每天25分钟，快速攻克英语听说
    </div>
    <div style="position:relative;">
      <img style="display: block;width:1920rem;height:744rem;margin-top: 48rem;" src="../assets/app_download.png">
      <div
        style="position:absolute;left:770rem;top:524rem; width:140rem;height:140rem;border: 1rem solid rgba(108, 112, 119, 0.2);border-radius: 10rem;"
        @click="(down_idx = 0)">
        <div v-if="(down_idx != 0)" style="padding:20rem 40rem;"><img style="display:block;width:60rem;height:60rem;"
            src="../assets/apple_icon.png"></div>
        <div v-if="(down_idx != 0)" style="text-align: center;color:#283342;font-size: 16rem;">下载iOS版</div>
        <div v-if="(down_idx == 0)"><img style="display:block;width:140rem;height:140rem;"
            src="../assets/kefuerweima.png"></div>
      </div>
      <div
        style="position:absolute;left:1010rem;top:524rem; width:140rem;height:140rem;border: 1rem solid rgba(108, 112, 119, 0.2);border-radius: 10rem;"
        @click="(down_idx = 1)">
        <div v-if="(down_idx != 1)" style="padding:20rem 40rem;"><img style="display:block;width:60rem;height:60rem;"
            src="../assets/android_icon.png"></div>
        <div v-if="(down_idx != 1)" style="text-align: center;color:#283342;font-size: 16rem;">下载Android版</div>
        <div v-if="(down_idx == 1)"><img style="display:block;width:140rem;height:140rem;"
            src="../assets/kefuerweima.png"></div>
      </div>
    </div>
    <div
      style="position:absolute;left:769rem;top:0rem;width: 996rem;height: 996rem;background: #B6D7FF;opacity: 0.3;filter: blur(300rem);z-index: -1;">
    </div>
    <div
      style="position:absolute;left:173rem;top:150rem;width: 844rem;height: 844rem;background: #FFD8CC;opacity: 0.3;filter: blur(300rem);z-index: -1;">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      down_idx: -1
    }
  }
}
</script>

<style scoped>
#pagedownload .horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
}
</style>
