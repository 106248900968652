
<template>
  <div style="background: #f6f6f6;border-radius: 15rem;padding: 25rem;font-size: 18rem;">
    <div v-if = "show_update_panel" class="horizontal">
      <input style="font-size: 18rem;border-style: dotted;width:200rem;height: 30rem;padding:5rem;" placeholder="请设置用户名" id="user_name_id">
      <div style="margin-left:10rem;text-align: center; background: #666666;padding: 10rem 20rem;border-radius: 10rem;color:white;" @click="setUserInfo">确认</div>
    </div>
    <div v-else>
      <div class="horizontal" style="padding-bottom: 5rem;">
        <img style="display: block;width:28rem;height:28rem;" src="../assets/icp_loginshouji.png">
        <div style="color:#666666;font-size: 16rem;">新用户自动注册</div>
      </div>
      <input style="border-style:double;width:384rem;height: 30rem;padding:5rem;margin-bottom: 25rem;"
        placeholder="请输入手机号码" id="phone_num_id">

      <div class="horizontal" style="padding-bottom: 5rem;">
        <img style="display: block;width:28rem;height:28rem;" src="../assets/icp_loginyanzhengma.png">
        <div style="color:#666666;font-size: 16rem;">验证码</div>
      </div>
      <div class="horizontal" style="width:394rem;">
        <input style="border-style:double;width:263rem;height: 30rem;padding:5rem;"
          placeholder="请输入验证码" id="verify_num_id">
        <div style="margin-left:5rem;background: #cccccc; width:105rem;" @click="getVerifyCode">
          <div style="text-align: center; padding:12rem 0;color:white;">获取验证码</div>
        </div>
      </div>

      <div style="display: flex;align-items: center;flex-direction: column;margin-top: 30rem;">
        <div style="font-size: 15rem;margin-bottom:10rem;">
          <input type="radio" v-model="remember_acount_status" value=0
            @click="rememberAcount">
          记住账号
        </div>
        <div
          style="font-size:22rem;width:160rem;text-align: center; background: #715af6;padding: 10rem;border-radius: 5rem;color:white;"
          @click="landing">
          登录
        </div>
      </div>
    </div>

    <div v-if = "tips.length>0" style="margin-top:20rem;">{{ tips }}</div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {},
  data() {
    return {
      remember_acount_status: false,
      
      show_update_panel:false,

      tips:"",
      verify_info:null
    }
  },

  methods: {
    landing(){
      if(this.verify_info!=null){
        if(this.verify_info.user_name.length<=0){
          this.show_update_panel = true
        } else {
          this.tips = ""
          if(this.remember_acount_status){
            localStorage.setItem("user_id", this.verify_info.user_id)
            localStorage.setItem("user_name", this.verify_info.user_name)
            localStorage.setItem("phone_num", this.verify_info.phone_number)
          }
          this.$emit('user_info', this.verify_info)
        }
      }
    },
    rememberAcount(){
      this.remember_acount_status = !this.remember_acount_status
      console.log("remember_acount_status",this.remember_acount_status)
    },
    async getVerifyCode() {
      var numReg = /^[0-9]*$/
      var numRe = new RegExp(numReg)
      var phone_num = document.getElementById("phone_num_id").value
      if (phone_num.length != 11 || !numRe.test(phone_num)) {
        this.tips = '手机号格式错误'
        return
      }

      let request_data = {
        "type": "phone_register",
        "data": {
          "phone_number": phone_num,
          "name": ""
        }
      }
      await axios.post('https://caixiaowen.com/tongtu', JSON.stringify(request_data))
        .then((result) => {
          console.log(result.data)
          if(result.data.err_no==0){
            document.getElementById("verify_num_id").value = result.data.verify_code
            this.tips = "自动填入验证码"
            this.verify_info = result.data

            if(this.verify_info.user_name.length<=0){
              this.show_update_panel = true
              this.tips = ""
            }
          } else {
            this.tips = "注册失败!"
          }
          
        }).catch((error) => {
          console.log(error)
          return
        })
    },
    async setUserInfo() {
      var user_name = document.getElementById("user_name_id").value
      if(user_name.length<=0 || user_name.length>=20){
        this.tips = '用户名不符合要求'
        return
      }

      let request_data = {
        "type": "set_user_info",
        "data": {
          "user_id":this.verify_info.user_id,
          "user_name": user_name
        }
      }
      await axios.post('https://caixiaowen.com/tongtu', JSON.stringify(request_data))
        .then((result) => {
          console.log(result.data)
          if(result.data.err_no==0){
            this.verify_info.user_name = user_name
            this.show_update_panel = false
            this.tips = ""
            if(this.remember_acount_status){
              localStorage.setItem("user_id", this.verify_info.user_id)
              localStorage.setItem("user_name", this.verify_info.user_name)
              localStorage.setItem("phone_num", this.verify_info.phone_number)
            }
            this.$emit('user_info', this.verify_info)
          }
        }).catch((error) => {
          console.log(error)
          return
        })
    }
  }
}
</script>

<style scoped>
.horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
}
</style>
