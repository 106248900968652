<template>
  <div class="" id="pageabout">
    <div style="background:#F3F5F8">
      <div style="padding-top:150rem;text-align: center;color:#283342;font-size: 48rem;">
        通途AI英语
      </div>
      <div style="padding:40rem 480rem;text-align: center;color:#6C7077;font-size: 20rem;width:960rem;">
        是由前外研社国际人才学院（现国才创培中心）院长贾毅，联合AI科学家与美国语言学家共同创立，是全新一代职场英语、企业英语与国际化人才解决方案服务商。
      </div>
      <div>
        <img style="display: block;width:1170rem;height:400rem;padding: 40rem 375rem;" src="../assets/about_us_1.png">
      </div>
    </div>

    <div>
      <div style="color:#283342;text-align: center;padding-top:120rem;font-size:36rem;">
        通途AI英语首创5M职场英语/国际化人才训练体系
      </div>
      <div style="color:#6C7077;text-align: center;padding-top:20rem;font-size:20rem;">
        一站式解决职场英语/企业国际化人才的三大痛点
      </div>
      <div class="horizontal">
        <img style="padding:80rem 30rem 0rem 375rem;display:block;width:370rem;height:155rem;"
          src="../assets/tongdian_1.png">
        <img style="padding:80rem 30rem 0rem 0rem;display:block;width:370rem;height:155rem;"
          src="../assets/tongdian_2.png">
        <img style="padding:80rem 30rem 0rem 0rem;display:block;width:370rem;height:155rem;"
          src="../assets/tongdian_3.png">
      </div>
      <div style="color:#283342;text-align: center;padding-top:120rem;font-size:36rem;">
        与传统英语培训相比，通途AI英语具有三大优势
      </div>
      <div>
        <img style="padding:80rem 375rem;display:block;width:1170rem;height:200rem;" src="../assets/homepage_pic2.png">
      </div>

    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
#pageabout .horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
}
</style>
