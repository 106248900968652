
export function toInt(vv){
  var v=Math.abs(vv)
  v=(v>(parseInt(v)+0.5))?parseInt(v+1):parseInt(v)
  return (vv>=0)?v:-v
}

export function generateRandomString(length) {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters.charAt(randomIndex);
  }
  
  return result;
}

export const PLAY_OPTION = { 
  playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
  autoplay: true, //如果true,浏览器准备好时开始回放。
  muted: false, // 默认情况下将会消除任何音频。
  loop: false, // 导致视频一结束就重新开始。
  preload: 'auto',
  language: 'zh-CN',
  //aspectRatio: '3:4',
  fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
  sources: [], 
  //poster: require("../assets/cover_video.jpg"), //你的封面地址
  //width: document.documentElement.clientWidth, //播放器宽度
  notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
  flash: { hls: { withCredentials: false } },
  html5: { hls: { withCredentials: false } },
  controls: true,
  controlBar: {
    timeDivider: true,
    volumePanel: false,
    durationDisplay: true,
    remainingTimeDisplay: false,
    fullscreenToggle: true  //全屏按钮
  }   
}