<template>
  <div class="" id="pagehome">
    <van-swipe :autoplay="3500">
      <van-swipe-item v-for="(item, index) in [0,1,2,3]" :key="index">
        <div v-if="(item == 0)" class="head" :style="{ 'background-image': 'url(' + banner_img_1 + ')' }">
          <div style="padding-top:190rem;text-align:center;font-size:60rem;color:white;line-height: 80rem;">
            新一代<br>职场英语/国际化人才解决方案服务商
          </div>
          <div
            style="padding-top:10rem;text-align:center;font-size:16rem;color:white;opacity: 0.5;letter-spacing: 3rem;">
            The new generation of workplace English/international talent solution provider
          </div>
          <div>
            <img style="display: block; width:1170rem; height:200rem;padding-left: 375rem;padding-top: 110rem;"
              src="../assets/homepage_pic2.png">
          </div>
        </div>
        <div v-if="(item == 1)" class="head"
          style="background: linear-gradient(180deg, #3F7BD7 0%, #5A9CFF 59%, #FFFFFF 86%);">
          <div style="padding-top:130rem;text-align:center;font-size:48rem;color:white;line-height: 60rem;">
            通途AI英语
          </div>
          <div
            style="padding-top:10rem;text-align:center;font-size:36rem;color:white;line-height: 60rem;font-weight: lighter;">
            一站式解决职场英语沟通/国际化人才三大痛点
          </div>
          <div>
            <img style="display: block; width:1170rem; height:384rem;padding-left: 375rem;padding-top: 60rem;"
              src="../assets/homepage_pic4.png">
          </div>
        </div>
        <div v-if="(item == 2)" class="head" style="background: linear-gradient(180deg, #3F7BD7 0%, #FFFFFF 30%);">
          <div style="padding-top:130rem;text-align:center;font-size:48rem;color:#283342;line-height: 60rem;">
            学习平台接入
          </div>
          <div
            style="padding-top:10rem;text-align:center;font-size:36rem;color:#283342;line-height: 60rem;font-weight: lighter;">
            打造企业专属学习系统
          </div>
          <div>
            <img style="display: block; width:1170rem; height:434rem;padding-left: 375rem;padding-top: 60rem;"
              src="../assets/homepage_pic6.png">
          </div>
        </div>
        <div v-if="(item == 3)" class="head" :style="{ 'background-image': 'url(' + banner_img_4 + ')' }">
          <div style="padding:220rem 0 0 352rem;font-size:48rem;color:white;">
            <div style="padding-bottom: 35rem;">世界500强标准英语表达</div>
            <div style="padding-bottom: 15rem;">专属内容定制：</div>
            <div style="padding-bottom: 35rem;font-size:36rem;font-weight:lighter;">
              &nbsp;&nbsp;公司介绍&nbsp;&nbsp;产品说明&nbsp;&nbsp;业务沟通
            </div>
            <div>训练即实战</div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>

    <div style="color:#283342;text-align:center;padding-top:120rem;font-size:36rem;">
      通途AI英语5M国际沟通力训练体系
    </div>
    <div class="horizontal" style="flex-wrap: wrap;padding-left: 375rem;padding-top: 50rem;">
      <div v-for="(item, index) in tixi1" :key="index">
        <div class="tixi1" :style="{ 'background': 'url(' + tixi1[index].img + ')', 'background-size': 'cover' }"
          @mousemove="tixi1_idx = index" @mouseleave="tixi1_idx = -1">
          <div style="background: black;opacity: 0.4;width: 370rem;height: 370rem;">
            <div style="padding:50rem 30rem 20rem 50rem;color:white;font-size:24rem;">{{ tixi1[index].t1 }}</div>
            <div style="width:90rem;height:2rem;background:white;"></div>
            <div style="padding:20rem 30rem 30rem 50rem;color:white;font-size:14rem;opacity: 0.9;line-height: 25rem;">
              {{ tixi1[index].t2 }}</div>
          </div>
          <div v-if="(tixi1_idx == index)" class="tixibig"
            :style="{ 'background': 'url(' + tixi1[index].img + ')', 'background-size': 'cover' }">
            <div style="background: #2172EB;opacity: 0.8;width: 500rem;height: 500rem;">
              <div style="padding:67rem 40rem 27rem 67rem;color:white;font-size:32rem;">{{ tixi1[index].t1 }}</div>
              <div style="width:110rem;height:2rem;background:white;"></div>
              <div style="padding:27rem 40rem 40rem 67rem;color:white;font-size:20rem;opacity: 0.9;line-height: 35rem;">
                {{ tixi1[index].t2 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="width:1920rem;height:890rem;background:#F3F5F8;margin-top: 120rem;">
      <div style="color:#283342;text-align: center;padding-top:120rem;font-size:36rem;">
        5M国际沟通力培训方案定制设计
      </div>
      <div style="color:#6C7077;text-align: center;padding-top:20rem;font-size:20rem;">
        培训方案设计 企业需求对接
      </div>
      <img style="display: block; width:1027rem; height:597rem;padding-left: 447rem;padding-top: 85rem;"
        src="../assets/homepage_pic3.png">
    </div>

    <div style="color:#283342;text-align:center;padding-top:120rem;font-size:36rem;">
      5M国际沟通力训练体系
    </div>
    <div style="color:#6C7077;text-align:center;padding-top:20rem;font-size:20rem;line-height: 30rem;">
      英语学习与AI科技的完美融合<br>M1 攻克听说 | M2 流利对话 | M3 模拟实战 | M4 世界500强沟通法 | M5 跨文化能力特训
    </div>
    <div class="horizontal" style="padding-left: 730rem;padding-top: 80rem;">
      <div style="width:100rem;" v-for="(item, index) in [1, 2, 3, 4, 5]" :key="index">
        <div v-if="(index == tixi2_idx)">
          <div style="color:#3988FF;font-size:30rem;padding-left: 10rem;">M{{ item }}</div>
          <div style="width:60rem;height:2rem;background:#3988FF;margin-top:10rem;"> </div>
        </div>
        <div v-else>
          <div style="color:#283342;font-size:30rem;padding-left: 10rem;">M{{ item }}</div>
        </div>
      </div>
    </div>
    <div class="horizontal" style="padding-left: 375rem;padding-top: 50rem;align-items: center;">
      <img style="display: block;width: 770rem;height: 450rem;" v-bind:src="tixi2[tixi2_idx].img">
      <div style="padding-left:50rem;padding-right: 375rem;">
        <div style="background:#3988FF;color: white;font-size: 20rem;width:30rem;padding: 5rem;border-radius: 5rem;">
          M{{ (tixi2_idx + 1) }}</div>
        <div style="color:#283342;font-size: 24rem;padding-top: 30rem;">
          {{ tixi2[tixi2_idx].t1 }}
        </div>
        <div style="color:#6C7077;font-size: 16rem;padding-top: 20rem;line-height:24rem;">
          {{ tixi2[tixi2_idx].t2 }}
        </div>
      </div>
    </div>

    <div style="color:#283342;text-align:center;padding-top:120rem;font-size:36rem;">
      企业合作
    </div>
    <div style="color:#6C7077;text-align: center;padding-top:20rem;font-size:20rem;line-height: 30rem;">
      通途已和国内多家企业合作
    </div>
    <div class="horizontal" style="flex-wrap: wrap;padding: 60rem 335rem 0rem 375rem;">
      <div style="margin-right: 30rem;" v-for="(item, index) in company" :key="index">
        <img style="display:block;width:270rem;height: 130rem;" v-bind:src="item.img">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      banner_img_1: require("../assets/homepage_pic1.png"),
      banner_img_4: require("../assets/homepage_pic5.png"),

      tixi1_idx: -1,
      tixi1: [{
        img: require("../assets/tixi1_m0.png"),
        t1: "培训方案设计",
        t2: "专业项目团队配置，企业需求对接，企业英语沟通场景分析，5M培训方案定制设计，培训流程设计，企业培训平台对接分析，打造企业专属国际化能力训练平台"
      }, {
        img: require("../assets/tixi1_m1.png"),
        t1: "M1 通途英语AI课",
        t2: "独家LEKS英语听说训练系统，先进的AI口语测评技术，每天25分钟，快速攻克英语听说。500+真实职场沟通AI课，打造超级国际沟通力"
      }, {
        img: require("../assets/tixi1_m2.png"),
        t1: "M2 通途真人外教1对1",
        t2: "全球优质欧美外教，标准化管理，无时差服务，随时随地随心约课，足不出户享受全球顶级外教服务"
      }, {
        img: require("../assets/tixi1_m3.png"),
        t1: "M3 通途企业定制AI课",
        t2: "业内首家企业/行业英语训练快速定制化服务100%真实内容：公司介绍，产品说明，业务沟通，训练即实战，让英语培训不在隔靴搔痒，直击企业真实场景，让培训100%转化为企业收益！"
      }, {
        img: require("../assets/tixi1_m4.png"),
        t1: "M4 世界500强实战沟通课",
        t2: "业内首家世界500强实战沟通课，大数据分析世界500强10万词+语料库，提炼顶级国际企业沟通精华，12小时带你升级国际级英语沟通法！"
      }, {
        img: require("../assets/tixi1_m5.png"),
        t1: "M5 CAW跨文化能力测评与实战工作坊",
        t2: "全球权威CAW跨文化能力测评， 150+国家行为特征分析，一图对比加策略建议，100%洞悉全球文化特征，让你成为更有效的跨文化沟通者！"
      }],

      tixi2_idx: 0,
      tixi2: [{
        img: require('../assets/tixi2_m1.png'),
        t1: '通途英语AI课，每天25分钟，快速攻克英语听说',
        t2: '独家LEKS听说训练系统，极简易用设计，AI语音测评技术，国际标准进阶体系，碎片化时间学习，快速攻克英语听说，500+真实场景，打造超级国际沟通力！'
      }, {
        img: require('../assets/tixi2_m2.png'),
        t1: '通途真人外教1对1，全球优质外教，随时随地约课',
        t2: '英美加澳优选母语外教，全球服务随时约课，生活与职业两大标准课程体系，电脑/手机/平板随时随地随心学，轻松实现外教真实沟通。'
      }, {
        img: require('../assets/tixi2_m3.png'),
        t1: '通途企业定制AI课，100%真实内容，训练即实战！',
        t2: '定制您的公司介绍，提升企业国际形象！定制真实产品说明，快速提升产品销售！定制全面业务沟通，顺畅交流搞定客户！'
      }, {
        img: require('../assets/tixi2_m4.png'),
        t1: '世界500强实战沟通课，国际级英语沟通法',
        t2: '世界500强实战沟通课，谷歌如何高效沟通？苹果如何做产品说明？世界500强国际级沟通方法，让你12小时升级国际级沟通！'
      }, {
        img: require('../assets/tixi2_m5.png'),
        t1: '跨文化沟通能力测评与实战工作坊，秒懂世界',
        t2: '国际跨文化大师Hofstede六维度测评理论，国内首个跨文化职场能力测评，全球150+国家数据分析，360度分析你与沟通对象的思维与行为差异，知己知彼，百战百胜!'
      }],

      company: [{
        img: require('../assets/company-1.png')
      }, {
        img: require('../assets/company-2.png')
      }, {
        img: require('../assets/company-3.png')
      }, {
        img: require('../assets/company-4.png')
      }, {
        img: require('../assets/company-5.png')
      }, {
        img: require('../assets/company-6.png')
      }, {
        img: require('../assets/company-7.png')
      }, {
        img: require('../assets/company-8.png')
      }]
    }
  },

  created() {
    console.log("route", window.location.href)
    document.title = '职场英语 | 北京叔牙信息科技有限公司'

    this.loopPlay()
  },

  methods: {
    loopPlay() {
      var that = this
      setTimeout(function () {
        that.tixi2_idx = (that.tixi2_idx + 1) % 5
        that.loopPlay()
      }, 5000)
    },
  },
}
</script>

<style scoped>
#pagehome .head {
  width: 1920rem;
  height: 750rem;
  background-size: 'cover';
}

#pagehome .horizontal {
  flex-direction: row;
  display: flex;
  position: relative;
}

#pagehome .tixi1 {
  width: 370rem;
  height: 370rem;
  margin: 30rem 30rem 0 0;
  position: relative;
}

#pagehome .tixibig {
  width: 500rem;
  height: 500rem;
  position: absolute;
  left: -65rem;
  top: -65rem;
  z-index: 999;
}
</style>
